import ReportsListItem from './ReportsListItem';

function PendingReportsList({reports, userRole, userSettings}) {

	const deleteReport = () => {
		alert("Pending reports can not be deleted")
	}

	return (
		<>
			{reports.map((report) => <ReportsListItem {...{userRole, deleted: false, mode: "pending", deleteReport, report, key: report['report_id'], userSettings }} /> )}
		</>
	)
}

export default PendingReportsList;