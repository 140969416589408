import EpimsLogo from './components/EpimsLogo';
import Button from 'react-bootstrap/Button';

function LandlordHomePage(props) {

  return (
    <div className="d-flex justify-content-between">
        <div>
          <EpimsLogo small={true}/>
        </div>
        <div className="flex-fill">
          This is the landlord area. There is nothing here yet.
        </div>
        <div className="col-1">
          <Button className="btn" onClick={props.doLogout}>Log out</Button>
        </div>
      </div>
  );

}

export default LandlordHomePage;
