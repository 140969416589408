import { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SharedReportNotFound from "./SharedReportNotFound";

function PremiumLinkLandingPage(props) {

  const routeParams = useParams();

  const [reportData, setReportData] = useState(null);

  useEffect(() => {

    const url = process.env.REACT_APP_API_END_POINT + 'sharedpremiumreport';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        "report_path": routeParams.id + '/' + routeParams.slug
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        setReportData(false);
      } else {
        window.location.replace(data['pdfUrl']);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportData === null) {
    return ( <div className="my-5" style={{ textAlign: "center" }}>
              <div className="my-5">Fetching your epIMS Premium Report</div>
              <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "150px", width: "150px" }} /></div>
            </div>
          )
  } else if (reportData === false) {
    return ( <SharedReportNotFound /> )
  }
}

export default PremiumLinkLandingPage;