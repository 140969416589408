import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Account(props) {

  const navigate = useNavigate();
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [saveDisabled, setSaveDisabled] = useState(false);

  const [error, setError] = useState('');

  const [email, setEmail] = useState('');

  const [name, setName] = useState('');
  const [nameExtraClasses, setNameExtraClasses] = useState('');
  const [nameError, setNameError] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordExtraClasses, setCurrentPasswordExtraClasses] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordExtraClasses, setNewPasswordExtraClasses] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordExtraClasses, setConfirmNewPasswordExtraClasses] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

  const applySettings = (event) => {
    event.preventDefault();
  }

  const goHome = () => {
    navigate('/home');
  }

  const doSaveSettings = (event) => {
    event.preventDefault();
    setError('Working...');
    setNameExtraClasses('');
    setNameError('');
    setCurrentPasswordExtraClasses('');
    setCurrentPasswordError('');
    setNewPasswordExtraClasses('');
    setNewPasswordError('');
    const url = process.env.REACT_APP_API_END_POINT + 'account/';
    
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          name,
          currentPassword,
          newPassword
        })
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          goHome();
        } else {
          if ('errors' in data) {
            data.errors.forEach(err => {
              if (err.key === 'name') {
                setNameExtraClasses('is-invalid');
                setNameError(err.error);
              }
              if (err.key === 'currentPassword') {
                setCurrentPasswordExtraClasses('is-invalid');
                setCurrentPasswordError(err.error);
              }
              if (err.key === 'newPassword') {
                setNewPasswordExtraClasses('is-invalid');
                setNewPasswordError(err.error);
              }
              setError('Please correct errors and try again.');
            });
          } else {
            setError(data['error']);
          }
        }
      });
    
  }

  useEffect(() => {
    const url = process.env.REACT_APP_API_END_POINT + 'account/';

    fetch(url, 
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setSettingsLoaded(true);
          // depending on when the user was created the settings may not exist or even be set
          var settings = data['settings'];
          setEmail(settings['email']);
          setName(settings['name']);
        } else {
          navigate('/');
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSaveDisabled(false);
    setConfirmNewPasswordExtraClasses('');
    setConfirmNewPasswordError('');
    if (newPassword !== '' || confirmNewPassword !== '') {
      if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordExtraClasses('is-invalid');
        setConfirmNewPasswordError("Dose not match");
        setSaveDisabled(true);
      }
    }
  }, [newPassword, confirmNewPassword]);

  return (
    <>
      <div className="contentsAreaHeader">
        <div className="areaTitle">My Account</div>
        <div className="areaExplainer">Set your personal data and how you access your account</div>
      </div>
      <div className="contents">
        <div style={{width: "600px"}}>

          { !settingsLoaded ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> : 
          
            <form onSubmit={applySettings}>

              <div className="form-group pb-2">
                <label htmlFor="emailInput">Email</label>
                <input id="emailInput" className="form-control" type="text" value={email} disabled />
                <small id="emailHelp" className="form-text text-muted">Please contact support if you need to change your email address.</small>
              </div>

              <div className="form-group pb-2">
                <label htmlFor="nameInput">Full name</label>
                <input id="nameInput" className={"form-control " + nameExtraClasses} type="text" placeholder="Joe Bloggs" value={name} onChange={(e) => setName(e.target.value)} aria-describedby='validationName' />
                <div id="validationName" className="invalid-feedback">{nameError}</div>
                <small id="nameHelp" className="form-text text-muted">So that we know what to call you if we need to get in touch.</small>
              </div>

              <p className="pt-3">To change your password please provide your current password.</p>
              <div className="form-group">
                <label htmlFor="currentPasswordInput">Current password</label>
                <input id="currentPasswordInput" className={"form-control " + currentPasswordExtraClasses} type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} aria-describedby='validationCurrentPassword' />
                <div id="validationCurrentPassword" className="invalid-feedback">{currentPasswordError}</div>
              </div>
              <div className="form-group">
                <label htmlFor="newPasswordInput">New password</label>
                <input id="newPasswordInput" className={"form-control " + newPasswordExtraClasses} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} aria-describedby='validationNewPassword' />
                <div id="validationNewPassword" className="invalid-feedback">{newPasswordError}</div>
                <small id="newPasswordHelp" className="form-text text-muted">Must be at least 8 characters and contain at least one number or symbol.</small>
              </div>
              <div className="form-group">
                <label htmlFor="confirmNewPasswordInput">Confirm new password</label>
                <input id="confirmNewPasswordInput" className={"form-control " + confirmNewPasswordExtraClasses} type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} aria-describedby='validationConfirmNewPassword' />
                <div id="validationConfirmNewPassword" className="invalid-feedback">{confirmNewPasswordError}</div>
              </div>

              <div className="form-group pt-2">
                <button type="submit" onClick={doSaveSettings} className="btn btn-primary btn-sm" disabled={saveDisabled}>Save</button>
                &nbsp;
                <button type="button" onClick={goHome} className="btn btn-secondary btn-sm">Cancel</button>&nbsp;{error}
              </div>

            </form>
          }
        </div>
      </div>
    </>
  );

}

export default Account;
