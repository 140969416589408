import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { publish } from './events';
import UploadXmlForm from './components/UploadXmlForm';
import UploadXmlStripe from './components/UploadXmlStripe';

function UploadXmlPage(props) {

  const navigate = useNavigate();
  const [showStripe, setShowStripe] = useState(false);
  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [unpaidReportId, setUnpaidReportId] = useState("");

  const generatePremiumReport = (xmlAFilename, xmlBFilename, originalAFilename, originalBFilename, commissioner, cTaxPropertyAddress, manualCouncilTaxBandActive, manualCTaxBand, useCredit) => {
      const url = process.env.REACT_APP_API_END_POINT + 'buypremiumreportcheckoutinit';
      var requestBody = {
          "xml1_key": xmlAFilename,
          "xml2_key": xmlBFilename,
          "xml1_original_filename": originalAFilename,
          "xml2_original_filename": originalBFilename,
          commissioner,
          cTaxPropertyAddress,
          useCredit
      };
      if (manualCouncilTaxBandActive) {
        requestBody['manualCTaxBand'] = manualCTaxBand;
        requestBody['cTaxPropertyAddress'] = '';
      }
      fetch(url, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(requestBody),
          credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
          if (data['success']) {
            if (!useCredit) {
              setStripeClientSecret(data['cs'])
              setUnpaidReportId(data['rid'])
              setStripePublishableKey(data['pk'])
              setShowStripe(true)
            } else {
              navigate('/pending');
            }
          } else {
              alert(data['error']);
          }
      });
  }

  const generatePreliminaryReport = (xmlAFilename, xmlBFilename, originalAFilename, originalBFilename, commissioner, cTaxPropertyAddress, manualCouncilTaxBandActive, manualCTaxBand) => {
    // xmlFilename contains the S3 key
    // originalFilename contains the orignal filename, needed if we're going to try to extract agent ID from it
    const url = process.env.REACT_APP_API_END_POINT + 'reports';
    var requestBody = {
      "xml1_key": xmlAFilename,
      "xml2_key": xmlBFilename,
      "xml1_original_filename": originalAFilename,
      "xml2_original_filename": originalBFilename,
      commissioner,
      cTaxPropertyAddress,
    };
    if (manualCouncilTaxBandActive) {
      requestBody['manualCTaxBand'] = manualCTaxBand;
      requestBody['cTaxPropertyAddress'] = '';
    }
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(requestBody),
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          publish('newReport');
          navigate('/home');
        } else {
          alert(data['error']);
        }
      });
  }

  const getContents = () => {
    if (showStripe) {
      return <UploadXmlStripe {...{stripeClientSecret, unpaidReportId, stripePublishableKey, setShowStripe}} />
    } else {
      return <UploadXmlForm {...{generatePreliminaryReport, generatePremiumReport, userAccreditationScheme: props.userAccreditationScheme}} />
    }
  }

  return (
    <>
      <div className="contentsAreaHeader">
        <div className="areaTitle">Create Report</div>
        <div className="areaExplainer">To create a new epIMS report, start by uploading the LIG and RdSAP XML files from the original EPC survey using the boxes below.</div>
      </div>
      <div className="contents">
        {getContents()}
      </div>
    </>
  )
}

export default UploadXmlPage;
