function EpimsLogoOnly(props) {

    const small = props.small ?? false;
    const xsmall = props.xsmall ?? false;
    const xxsmall = props.xxsmall ?? false;
    const xxxsmall = props.xxxsmall ?? false;

    var width;
    if (xxxsmall) {
        width = "5rem";
    }
    else if (xxsmall) {
        width = "8rem";
    }
    else if (xsmall) {
        width = "13rem";
    }
    else if (small) {
        width = "18rem";
    }
    else {
        width = "23rem";
    }

    return (
        <img style={{ width: width }} src="/epims_logo.png" alt="epIMS logo" />
    );
}

export default EpimsLogoOnly;