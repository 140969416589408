import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function RegisterCheckPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [code, setCode] = useState('');
  const [codeExtraClasses, setCodeExtraClasses] = useState('');
  const [codeError, setCodeError] = useState('');

  const doCheckCode = (code) => {
    setCode(code);
    setCodeExtraClasses('');
    setCodeError('');
    var codeTidy = code.split(' ').join('');
    if (codeTidy.length === 6) {
      
      // validate....
      const url = process.env.REACT_APP_API_END_POINT + 'uservalidate';
      fetch(url,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
            username: state.username,
            code: codeTidy
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            navigate('/', {
              state: {
                username: state.display_username,
                postSignup: true
              }
            });
          } else {
            setCodeExtraClasses('is-invalid');
            setCodeError('Incorrect code');
          }
        })
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column">
        <div className="card" style={{ width: '45rem' }}>
          <div className="card-header"><strong>Thank you!</strong></div>
          <div className="card-body">
            <div>Thank you for registering with epIMS!</div>
            <div>We need to verify you own the email address <strong>{state.email}</strong>.</div>
            <div>We have sent you an email with a confirmation code, please enter that code here or just click the link in the email.</div>
            <div>If you can't find the email please check your spam folder.</div>
            <form style={{ width: "15rem" }}>
              <div className="form-group pb-2">
                <label htmlFor="codeInput">Email confirmation code:</label>                  
                <input id="codeInput" className={"form-control " + codeExtraClasses} type="text" placeholder="######" value={code} onChange={(e) => doCheckCode(e.target.value)} aria-describedby="validationCode" />
                <div id="validationCode" className="invalid-code">{codeError}</div>
              </div>
            </form>
            <button type="button" className="btn btn-primary btn-sm">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterCheckPage;
