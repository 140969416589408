import React from "react";

function Paginator({currentPage, setPageNumber, itemCount, pageSize, goPreviousPage, goNextPage}) {

    const maxPage = Math.ceil(itemCount / pageSize);

    var pageNumbers = [];

    // always show page 1
    pageNumbers.push(1);

    // show some pages around the current page
    var i;
    const rangeStart = Math.max(2, currentPage - 2);
    for (i = rangeStart; i <= rangeStart + 4; i++) {
      pageNumbers.push(i)
    }
    const rangeEnd = Math.min(currentPage + 2, maxPage - 1);
    for (i = rangeEnd - 4; i <= rangeEnd; i++) {
      pageNumbers.push(i)
    }

    // always show the last page
    pageNumbers.push(maxPage)

    // remove any out of range
    pageNumbers = pageNumbers.filter(n => n >= 1 && n <= maxPage);
    // remove any dupes
    pageNumbers = [...new Set(pageNumbers)]
    // make sure they're in order (should be)
    pageNumbers = pageNumbers.sort(function(a, b) {
        return a - b;
    });

    if (maxPage <= 1) return null;

    return (
        <div className="paginator">
            <p style={{display: "inline", marginRight: "10px"}}>Page:</p>
            <button className={currentPage === 1 ? 'pageLinkDisabled' : ''} onClick={goPreviousPage}>Previous</button>
            {pageNumbers.map(n => {return (<button className={currentPage === n ? 'currentPage' : ''} onClick={() => setPageNumber(n)}>{n}</button>)})}
            <button className={currentPage === maxPage ? 'pageLinkDisabled' : ''} onClick={goNextPage}>Next</button>
        </div>
    )
}

export default Paginator;