import React , { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './LoginPageStyle.css';
import EpimsLogoOnly from './components/EpimsLogoOnly';

function LoginPage(props) {

  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState(location?.state?.username ?? "");
  const postSignup = (location?.state?.postSignup ?? false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const doLogin = (event) => {
    setLoginButtonDisabled(true);
    setError("Wait...");
    const url = process.env.REACT_APP_API_END_POINT + 'login';
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          username,
          password
        }),
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        setLoginButtonDisabled(false);
        if (data['success']) {
          props.setIsLoggedIn(true);
          props.setUserRole(data['role']);
          props.setUserRealName(data['name']);
          navigate('/home');
        } else {
          setError(data['error']);
        }
      });
    event.preventDefault();
  }

  const navigateForgotPassword = (event) => {
    navigate('/forgotpassword');
    event.preventDefault();
  }

  useEffect(() => {
    if (props.isLoggedIn) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="login-page-container">
        <div className="logo-container">
          <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/"}><EpimsLogoOnly xsmall /></a>
        </div>

        <div className= "login-container">
          <div className="login-inset"></div>
          <div className="login-left">
            <h2>Increase your revenue at the touch of a button</h2>
            <p>
              epIMS (Energy Performance Integrated Management System) is a free to use on-line, energy efficiency analysis and reporting tool,
              developed as an enhancement to an EPC survey, a source of advice in the absence of an EPC and as a cost-effective alternative to a Retrofit Report.
            </p>
            <p>
              A range of valuable add-ons are available, which can simplify and substantially reduce the cost of improving the energy efficiency of any UK residential property.
            </p>
            <p>
              epIMS is available to Property Professionals, including Energy Assessors, Estate Agents and Property Management Consultants who want to impress their customers,
              enhance their current range of services, increase revenue or simplify current processes in a secure and efficient environment.
            </p>
            <p>Further details are available from Ross Hopgood (<a href="mailto:ross@epims.co.uk">ross@epims.co.uk</a>) Tel: 0161 832 3280</p>
          </div>
          <div className="login-right">
            { postSignup && (
              <div className="login-message">
                <p>Thank you for registering with epIMS! Please login below to begin</p>
              </div>
            )}
            <div className="card form-style">
              <div className="card-header mobile-center" ><strong>Login to your epIMS account</strong></div>
              <div className="card-body">
                <form onSubmit={doLogin}>
                  <div className="form-group pb-2">
                    <label htmlFor="usernameInput" className="pb-1">Username (email)</label>
                    <input id="usernameInput" className="form-control" type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
                  </div>
      
                  <div className="form-group pb-2">
                    <label htmlFor="passwordInput" className="pb-1">Password</label>
                    <input id="passwordInput" className="form-control" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                  </div>
      
                  <div className="form-group mobile-center">
                    <button type="submit" disabled={loginButtonDisabled} className="btn btn-primary btn-sm">Login</button>&nbsp;<span>{error}</span>
                  </div>
      
                  <div className="form-group pt-1 mobile-center">
                    <span><a href="/forgotpassword" onClick={navigateForgotPassword}>Forgot password?</a></span>
                  </div>
                </form>
              </div>
      
              <div className="card-footer">
                Don't have an account? <Link to='signup'>Sign up here</Link>
              </div>
            </div>
          </div>
        </div> 
        <div className="epa-logo-container">
          <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/"}><img alt="epIMS is an EPA (Energy Performance Advice) company - Patent Pending" src="/epims_epa.png" /></a>
        </div>
      </div>
    </>
  );


}

export default LoginPage;