import { useEffect } from 'react';
import ReportsList from './components/ReportsList';
import UploadXmlPage from './UploadXmlPage';
import EpimsLogoOnly from './components/EpimsLogoOnly'
import Account from './Account';
import { subscribe, unsubscribe } from './events';
import FriendlyGreeting from './components/FriendlyGreeting';
import Settings from './Settings';
import NavOption from './components/NavOption';
import EarningsPage from './EarningsPage';
import PendingReports from './components/PendingReports';

function HomePage(props) {

  // on component mount, fetch the latest user settings (this is mostly to get the email template text) and then subscribe to messages indicating that the settings have changed
  // on one of those messages, fetch again
  // on unmount, unsubscribe
  useEffect(() => {
    props.fetchUserSettings();
    const handleSettingsUpdateMessage = (body) => {
      props.fetchUserSettings();
    }
    subscribe('settingsUpdate', handleSettingsUpdateMessage);
    return () => {
      unsubscribe('settingsUpdate', handleSettingsUpdateMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainComponent = () => {
    if (props.activeTab === 'myaccount') return <Account />
    if (props.activeTab === 'settings') return <Settings />
    if (props.activeTab === 'main') return <ReportsList key="regular" userSettings={props.userSettings} mode="preliminary" deleted={false} userRole={props.userRole} />
    if (props.activeTab === 'deleted') return <ReportsList key="deleted" userSettings={props.userSettings} mode="preliminary" deleted={true} userRole={props.userRole} />
    if (props.activeTab === 'new') return <UploadXmlPage userAccreditationScheme={props.userAccreditationScheme} />
    if (props.activeTab === 'pending') return <PendingReports userRole={props.userRole} userSettings={props.userSettings} />
    if (props.activeTab === 'premium') return <ReportsList key="premium" userSettings={props.userSettings} mode="premium" deleted={false} userRole={props.userRole} />
    if (props.activeTab === 'earnings') return <EarningsPage />
  }

  const showPremiumSection = (props.pendingReportsCount !== 0) || props.showPremiumReportsTab

  return (
    <div className="loggedInContainer">
      <div className="loggedInHeader"></div>
      <div className="loggedInMainArea">
        <div className="navMenu">
          <EpimsLogoOnly xxxsmall={true} />
          <FriendlyGreeting userRealName={props.userRealName} />
          <div className="navSection">
            <div className="navSectionName">Reports</div>
            <NavOption activeTab={props.activeTab} url='/uploadXml' name='new' displayName='Create Report' />
            <NavOption activeTab={props.activeTab} url='/home' name='main' displayName='Report List' />
            <NavOption activeTab={props.activeTab} url='/deleted' name='deleted' displayName='Deleted Reports' />
            <NavOption activeTab={props.activeTab} url='/earnings' name='earnings' displayName='My Earnings' />
          </div>
          {showPremiumSection && <div className="navSection">
            <div className="navSectionName">Premium Reports</div>
            {(props.pendingReportsCount !== 0) && <NavOption notifications={props.pendingReportsCount} activeTab={props.activeTab} url='/pending' name='pending' displayName='Pending' /> }
            {props.showPremiumReportsTab && <NavOption activeTab={props.activeTab} url='/premium' name='premium' displayName='Premium Reports' /> }
          </div> }
          <div className="navSection">
            <div className="navSectionName">Settings</div>
            <NavOption activeTab={props.activeTab} url='/settings' name='settings' displayName='Branding' />
            <NavOption activeTab={props.activeTab} url='/account' name='myaccount' displayName='My Account' />
          </div>
          <div style={{textAlign: "center", marginRight: "15px", marginTop: "24px"}}><button className="btn btn-primary" onClick={props.doLogout}>Log out</button></div>
          <div className="termsLinks">
              <p><a style={{textDecoration: "none"}} href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Privacy%20Policy.pdf"} target="_blank" rel="noreferrer">Privacy Policy</a></p>
              <p><a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Terms.pdf"} target="_blank" rel="noreferrer">Terms and Conditions</a></p>
          </div>
        </div>
        <div className="contentArea">
          { mainComponent() }
        </div>
      </div>
    </div>
  );

}

export default HomePage;
