import React from 'react';
import { Link } from 'react-router-dom';
import EpimsLogoNew from './components/EpimsLogoNew';

function ResetPasswordDonePage(props) {

  return (
    <div className="container pt-4 d-flex justify-content-center">
      <div className="d-flex flex-column">
        <div className="pb-4"><EpimsLogoNew /></div>
        <div className="card" style={{ width: '25rem' }}>
          <div className="card-header"><strong>Password reset</strong></div>
          <div className="card-body">
            <div>Your password has been reset</div>
          </div>
          <div className="card-footer">
          <Link to='/'>Back to login</Link>
         </div>
        </div>
      </div>
    </div>
  );


}

export default ResetPasswordDonePage;