import React , { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EpimsLogoNew from './components/EpimsLogoNew';


function ForgotPasswordPage(props) {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length < 6) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }

  const doSubmit = (event) => {
    setSubmitButtonDisabled(true);
    setError("Wait...");
    const url = process.env.REACT_APP_API_END_POINT + 'recoverpassword';
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          email,
        }),
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        navigate('/forgotpassworddone');
      });
    event.preventDefault();
  }

  return (
    <div className="container pt-4 d-flex flex-column justify-content-center align-items-center">
  
  <div className="row">
    <div className="col text-center pb-4">
      <EpimsLogoNew />
    </div>
  </div>

  <div className="row">
    <div className="col">
      <div className="card" style={{ width: '25rem' }}>
        <div className="card-header"><strong>Forgotten your password?</strong></div>
        <div className="card-body">
          <form onSubmit={doSubmit}>
            <div className="form-group">
              <label  className="pb-4" htmlFor="emailInput">Please enter the email address you registered with, and we will send you a link to reset your password.</label>
              <input id="emailInput" className="form-control" type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
            </div>

            <div className="form-group pt-2 text-center">
              <button type="submit" disabled={submitButtonDisabled} className="btn btn-primary btn-sm">Recover</button>&nbsp;<span>{error}</span>
            </div>
          </form>
        </div>
        <div className="card-footer">
          <Link to='/'>Back to login page</Link>
        </div>
      </div>
    </div>
  </div>
</div>

  );

}

export default ForgotPasswordPage;