import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import { subscribe, unsubscribe } from '../events'
import { EmbeddedCheckout } from "@stripe/react-stripe-js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function UploadXmlStripe({stripeClientSecret, unpaidReportId, stripePublishableKey, setShowStripe})
{
    const navigate = useNavigate();

    const [stripePromise, setStripePromise] = useState(null);
    const [stripeConfirmed, setStripeConfirmed] = useState(false);
    const [serverConfirmed, setServerConfirmed] = useState(false);

    const reportPaid = (event) => {
        if ((event.detail.reportId ?? '') === unpaidReportId) {
            setServerConfirmed(true);
        }
    }

    useEffect(() => {


        // Code review - WTF is this?
        setStripePromise(loadStripe(stripePublishableKey))


        subscribe('premiumReportPaymentComplete', reportPaid)
        return () => {
            unsubscribe('premiumReportPaymentComplete', reportPaid)
        }
        


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToEditPage = () => {
        navigate('/pending/' + unpaidReportId)
    }

    const getBottomContents = () => {
        if (stripeConfirmed) {
            if (serverConfirmed) {
                return (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <button className="btn btn-primary" onClick={() => {setShowStripe(false)}}>Create another report</button>&nbsp;
                        <button className="btn btn-primary" onClick={() => goToEditPage()}>Edit Premium Report</button>
                    </div>
                )
            } else {
                return (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
                        <p style={{margin: "0 0 0 20px"}}>epIMS is confirming your payment</p>
                    </div>
                )
            }
        } else {
            return null;
        }
    }

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                    clientSecret: stripeClientSecret,
                    onComplete: () => {
                        setStripeConfirmed(true);
                    }
                }} >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
            <div style={{textAlign: "center"}}>{getBottomContents()}</div>
        </div>
    )
}

export default UploadXmlStripe;