import React from 'react';
import { Link } from 'react-router-dom';
import EpimsLogoNew from './components/EpimsLogoNew';

function ForgotPasswordDonePage(props) {

  return (
    <div className="constainer pt-4 d-flex justify-content-center">
      <div className="d-flex flex-column">
        <div className='pb-4'><EpimsLogoNew /></div>
        <div className="card" style={{ width: '25rem' }}>
          <div className="card-header"><strong>Forgot password</strong></div>
          <div className="card-body">
            <div>If we have a user registered at that email address, we will send you a password recovery link.</div>
          </div>
          <div className="card-footer">
          <Link to='/'>Back to login</Link>
          </div>
        </div>
      </div>
    </div>
  );


}

export default ForgotPasswordDonePage;