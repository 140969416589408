import EpimsLogo from "./components/EpimsLogo";

function SharedReportNotFound() {

  return (
    <>
      <div className="topLeftLogo"><EpimsLogo /></div>
      <div className="sharedLandingColumn">
        <p className="sharedLandingTitle">Ooops!</p>
        <p className="sharedLandingSubtitle">We're sorry but it looks like the link you were given was the wrong one. Please contact the estate agent or surveyor who gave you the link.</p>
      </div>

    </>
  );
}

export default SharedReportNotFound;