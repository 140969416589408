import React from 'react';
import Button from 'react-bootstrap/Button';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function EditPendingReportButton(props) {

  const tooltip = (
    <Tooltip>Edit Premium Report</Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Button onClick={(e) => {
        // just let it pass through
      }} style={{"marginLeft": "5px"}} className="btn">
        <FontAwesomeIcon icon={solid("pen-to-square")} style={{height: "44px", aspectRatio: "1", color: "#FFFFFF"}} />
      </Button>
    </OverlayTrigger>
  );
}

export default EditPendingReportButton;