import EpimsLogo from './components/EpimsLogo';
import Button from 'react-bootstrap/Button';

function OtherUserTypeHomePage(props) {

  return (
    <div className="d-flex justify-content-between">
        <div>
          <EpimsLogo small={true}/>
        </div>
        <div className="flex-fill">
          epIMS is designed for landlords, estate agents and property surveyors. During registration you selected 'other', someone will be in touch to find out how we can best help you.
        </div>
        <div className="col-1">
          <Button className="btn" onClick={props.doLogout}>Log out</Button>
        </div>
      </div>
  );

}

export default OtherUserTypeHomePage;
