import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { subscribe, unsubscribe } from '../events'
import ProcessReportPage from '../ProcessReportPage'
import PendingReportsList from './PendingReportsList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


function PendingReports(props) {

    const [reports, setReports] = useState([]);
    const [reportsLoaded, setReportsLoaded] = useState(false);

    const routeParams = useParams();

    const fetchReportList = () => {
      
      var fUrl = process.env.REACT_APP_API_END_POINT + 'reportslistassessorpending/';
      fetch(fUrl,
        {
          headers: {
            'Accept': 'application/json',
          },
          method: "GET",
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            setReports(data['reports_list']);
            setReportsLoaded(true);
          } else {
            setReports([]);
            setReportsLoaded(false);
          }
        }
      );
    }

    // on component mount, fetch the latest report list (it'll get shown) and then subscribe to messages indicating that its been updated
    // on one of those messages, fetch again
    // on unmount, unsubscribe
    useEffect(() => {
      fetchReportList();
      const handleReportListUpdateMessage = (body) => {
        fetchReportList();
      };
      subscribe('pendingReportsListUpdate', handleReportListUpdateMessage);
      return () => {
        unsubscribe('pendingReportsListUpdate', handleReportListUpdateMessage);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getListContent = () => {
      if (!reportsLoaded) {
        return <div><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} /></div>
      }
      if (reports.length === 0) {
        return (
          <div className="fs-4" style={{ textAlign: "center" }}>You do not have any pending Premium Reports</div>
        );
      }
      return <PendingReportsList {...{reports, userRole: props.userRole, userSettings: props.userSettings}} />
    }

    if (routeParams.pendingReportId ?? false) {
      // show the UI to edit this specific report
      return (
        <>
          <div className="contentsAreaHeader">
            <div className="areaTitle">Finalise Premium Report</div>
            <div className="areaExplainer">Update the data you want to show in this Premium Report</div>
          </div>
          <div className="contents">
            <ProcessReportPage reportId={routeParams.pendingReportId} />
          </div>
        </>
      )
    } else {
      // show the list of pending reports
      return (
        <>
          <div className="contentsAreaHeader">
            <div className="areaTitle">Pending Premium Reports</div>
            <div className="areaExplainer">Premium reports that need additional information before completion</div>
          </div>
          <div className="contents">
            {getListContent()}
          </div>
        </>
      );
    }
}

export default PendingReports;