import React from "react";

function Footer(props)
{
    return (
        <div className="footer-inner-container">
            <span className="terms">
            <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Privacy%20Policy.pdf"} target="_blank" rel="noreferrer">Privacy Policy</a>
            <br />
            <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Terms.pdf"} target="_blank" rel="noreferrer">Terms and Conditions</a>
            </span>
        </div>
    )
}

export default Footer;