import React from 'react';

function EpimsLogo(props) {

    const small = props.small ?? false;
    var width;
    if (small) {
        width = "18rem";
    } else {
        width = "23rem";
    }
    return (
       <img style={{ width: width }} src="/epims logo 800x271.jpg" alt="epIMS logo"/>
    );

}

export default EpimsLogo;