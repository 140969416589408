import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function RegisterAutoCheckPage() {
    const navigate = useNavigate();
    const routeParams = useParams();

    const doCheckCode = () => {   
      // validate....
      const url = process.env.REACT_APP_API_END_POINT + 'uservalidate';
      fetch(url,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
            username: routeParams.username,
            code: routeParams.code
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            navigate('/', {
              state: {
                username: data['display_username'],
                postSignup: true
              }
            });
          } else {
            navigate('/', {
              state: {
                username: routeParams.username
              }
            });
          }
        })
    }

    useEffect(() => {
      doCheckCode();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div style={{textAlign: 'center'}}>
              <div className="my-5">Loading...</div>
              <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "250px", width: "250px" }} /></div>
      </div>
    );
}

export default RegisterAutoCheckPage;
