import React, { useEffect } from 'react';
// I'm leaving these here as a hint as I expect they will be needed at some point and it will be confusing that they aren't
// but for now the linter is complaining they they aren't in use so they get //'d
// import React, { useState } from 'react'
// import ReactDOM from 'react-dom';
import Page from './Page';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

function App() {

  useEffect(() => {
    if (!(window.amplitudeInitialised ?? false)) {
      window.amplitudeInitialised = true;

      // call to our endpoint that returns us an amplitude API key and a device ID
      // If we already have a device ID cookie set then use the one from the cookie
      // but in any event, the API key is needed (backend returns the right 
      // environment specific key)
      const url = process.env.REACT_APP_API_END_POINT + 'amplitude';
      fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          if (Cookies.get('epims_id') === undefined) {
            // we don't have a cookie with a device ID for this user, so use the one we just got from the API
            window.amplitudeDeviceId = data['newDevice'];
          } else {
            window.amplitudeDeviceId = Cookies.get('epims_id');
          }
          window.amplitude.init(data['apiKey'], null, {
            identityStorage: "none", // we're managing the cookies ourselves
            deviceId: window.amplitudeDeviceId
          });
        }
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <Page/>
    </BrowserRouter>
  );
}

export default App;
