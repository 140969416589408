import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ShareReportButton from './ShareReportButton';
import DeleteReportButton from './DeleteReportButton';
import PreviewReportButton from './PreviewReportButton';
import UnDeleteReportButton from './UnDeleteReportButton';
import EditPendingReportButton from './EditPendingReportButton';
import PermaDeleteReportButton from './PermaDeleteReportButton';


function ReportsListItem(props) {

    const navigate = useNavigate()

    const [pillClass, setPillClass] = useState("text-bg-info");

    const onClick = () => {
        if (props.mode === 'preliminary' || props.mode === 'premium') {
            if (props.report.status === "Ready!") {
                const url = process.env.REACT_APP_API_END_POINT + 'reporturl';
                fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({
                        "report_id": props.report.report_id,
                        "mode": props.mode
                    })
                })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data['success'] === true) {
                        const url = data['report_url'];
                        window.open(url, '_blank', 'noopener,noreferrer');        
                    }
                });
            }
        }
        if (props.mode === 'pending') {
            navigate('/pending/' + props.report.report_id)
        }
    }

    useEffect(() => {
        if (props.report.status === "Ready!") {
            setPillClass("reportStatusPrimary");
        } else if (props.report.status === "Error") {
            setPillClass("reportStatusDanger");
        } else if (props.report.status === "Regenerating") {
            setPillClass("reportStatusWarning");
        } else {
            setPillClass("reportStatusInfo");
        }
    }, [props.report.status]);

    const date = new Date(props.report.created_at * 1000);

    var epcRating = "";
    var epcExtraClasses = "dataCell";
    if (props.report.current_epc_band !== null && props.report.current_epc !== null) { // these can be null with some very old objects, probably won't need this after go live
        epcRating = props.report.current_epc_band + " (" + props.report.current_epc + ")";
        epcExtraClasses = "dataCellEpcRating" + props.report.current_epc_band
    }

    var collapsibleName = '';
    var mustShowName = '';
    if (props.report.status !== "Error") {
        const lastCommaIndex = props.report.name.lastIndexOf(',');
        collapsibleName = props.report.name.substring(0, lastCommaIndex);
        mustShowName = props.report.name.substring(lastCommaIndex + 1);
    } else {
        collapsibleName = props.report.name;
        mustShowName = '';
    }

    return ( 
        <div className="reportListItem" onClick={onClick}>
            <div className={ epcExtraClasses } style={{width: "100px", height: "73px", marginRight: "0px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <p style={{margin: "0", padding: "0", fontSize: "24px"}}><strong>{ epcRating }</strong></p>
            </div>

            <div className="reportDataArea">
        
                <div className="reportAddress">
                    {(props.mode === "preliminary" || props.mode === "premium") && <p><span style={{textWrap: "nowrap"}} className={"reportStatus " + pillClass}><strong>{props.report.status}</strong></span></p>}
                    <p className="address">&nbsp;{collapsibleName}</p>
                    <p className="postcode">, {mustShowName}</p>
                </div>
                
                <div className="reportMiniDataArea" style={{display: "flex", flexDirection: "row"}}>
                    { (props.mode === 'preliminary' || props.mode === 'premium') && <p>Report generated: { date.toLocaleDateString() }</p> }
                    { props.mode === 'pending' && <p>Paid: { date.toLocaleDateString() }</p> }
                    { props.report.premium_report_purchased ? <span className="goldText"><p><strong>Premium report purchased</strong></p></span> : null }
                </div>
                <div className="reportMiniDataArea" style={{display: "flex", flexDirection: "row"}}>
                    { props.userRole === "estate agent" ? <p>{ "Assessor ID: " + props.report.assessor_ref }</p> : null }
                    <p>{ "Report ID: " + props.report.report_ref }</p>
                </div>

            </div>

            <div className="reportActionsArea">
                <div>
                    { props.mode === 'preliminary' ?
                        <>
                            { props.deleted ?
                                <>
                                    <UnDeleteReportButton deleteReport={ props.deleteReport } report={ props.report } />
                                    <PermaDeleteReportButton deleteReport={ props.deleteReport } report={ props.report } />
                                </>
                            :
                                <>
                                    <PreviewReportButton />
                                    <ShareReportButton report={ props.report } userSettings={ props.userSettings } />
                                    <DeleteReportButton deleteReport={ props.deleteReport } report={ props.report } />
                                </>
                            }
                        </>
                        : null
                    }
                    { props.mode === 'pending' ?
                        <>
                            <EditPendingReportButton {...{report: props.report}} />
                        </>
                        : null
                    }
                    { props.mode === 'premium' ?
                        <>
                            <PreviewReportButton />
                            <ShareReportButton report={ props.report } mode="premium" userSettings={ props.userSettings } />
                        </>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default ReportsListItem;