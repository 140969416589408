import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import EpimsLogoNew from './components/EpimsLogoNew';
import { Link } from 'react-router-dom';

function ResetPasswordPage() {
    const navigate = useNavigate();
    const routeParams = useParams();
    const [showSpinner, setShowSpinner] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [changeButtonDisabled, setChangeButtonDisabled] = useState(true);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState('');

    const doCheckCode = () => {   
      // validate....
      const url = process.env.REACT_APP_API_END_POINT + 'resetpassword/?precheck=yes';
      fetch(url,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
            username: routeParams.username,
            code: routeParams.code
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            setShowSpinner(false);
          } else {
            navigate('/');
          }
        })
    }

    const doReset = (event) => {
      setWorking(true);
      setError('Working...');
      const url = process.env.REACT_APP_API_END_POINT + 'resetpassword/';
      fetch(url,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
            username: routeParams.username,
            code: routeParams.code,
            password
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            navigate('/resetpassworddone')
          } else {
            setWorking(false);
            setError(data['error']);
          }
        })
      event.preventDefault();
    }

    useEffect(() => {
      doCheckCode();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (password !== '' && password === passwordConfirm) {
        setChangeButtonDisabled(false);
      } else {
        setChangeButtonDisabled(true);
      }
    }, [password, passwordConfirm]);

    return (
      <div>
        { showSpinner ? 
          <div style={{textAlign: 'center'}}>
            <div className="my-5">Verifying...</div>
            <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "250px", width: "250px" }} /></div>
          </div>
        :
        <div className="container pt-4 d-flex justify-content-center">
        <div className="d-flex flex-column">
          <div className="pb-4"><EpimsLogoNew /></div>
          <div className="card" style={{ width: '25rem' }}>
            <div className="card-header"><strong>Reset password</strong></div>
            <div className="card-body">
              <p>Please choose a new password, it must be at least 8 characters long and contain one number or symbol.</p>
              <form onSubmit={doReset}>
                <div className="form-group pb-2">
                  <label htmlFor="passwordInput">Password</label>
                  <input id="passwordInput" className="form-control" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group pb-3">
                  <label htmlFor="passwordConfirmInput">Confirm password</label>
                  <input id="passwordConfirmInput" className="form-control" type="password" placeholder="Password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                </div>

                <div className="form-group text-center">
                  <button type="submit" disabled={changeButtonDisabled || working} className="btn btn-primary btn-sm">Change password</button>&nbsp;<span>{error}</span>
                </div>
                <div className="form-group">
                  <span><Link to='/'>Back to login</Link></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        }
      </div>
    );
}

export default ResetPasswordPage;
