import React from 'react';
import { useState , useEffect } from 'react';
import EpimsLogoNew from './components/EpimsLogoNew';
import { useSearchParams } from 'react-router-dom';

function PaymentCompletePage(props) {

  const [searchParams] = useSearchParams(); // const [searchParams, setSearchParams] = useSearchParams()
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {

    const url = process.env.REACT_APP_API_END_POINT + 'checkpaymentstatus';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        payment_intent: searchParams.get('payment_intent'),
        payment_intent_client_secret: searchParams.get('payment_intent_client_secret'),
        redirect_status: searchParams.get('redirect_status'),
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        // the API call failed
        setPaymentData({
          paid: false,
          short: 'Problem checking your payment',
          long: 'There was a problem checking the status of your payment. Please check with your card issuer.'
        });
      } else {
        setPaymentData(data['data']);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const paymentStatusTitle = () => {
    if (paymentData === null) {
      return "Checking payment status...";
    }
    if (paymentData['paid'] === true) {
      return "Payment successful!";
    } else {
      return paymentData['short'];
    }
  }

  const paymentStatusBody = () => {
    if (paymentData === null) {
      return <></>
    }
    if (paymentData['paid'] === true) {
      return (
        <>
          <p><strong>Congratulations!</strong> Your Premium Report for <strong className="text-secondary">{ paymentData.report_name }</strong> has been ordered.</p>  
          <p>An epIMS expert is customising your report, and it will be emailed to <strong className="text-secondary">{ paymentData.email }</strong> within 3 working days.</p>
        </>
      );
    } else {
      return (
        <>
          <p>{ paymentData['long'] }</p>
        </>
      );
    }
  }

  return (
    <div className="container pt-4">
  <div className="row justify-content-center">
    <div className="col-md-6">
      <div className="text-center pb-4">
        <EpimsLogoNew />
      </div>
      <div className="card">
        <div className="card-header"><strong>{ paymentStatusTitle() }</strong></div>
        <div className="card-body">
          { paymentStatusBody() }
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
    </div>
  </div>
</div>
  );
}

export default PaymentCompletePage;