import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function ShareReportButton(props) {

  const [show, setShow] = useState(false);

  const [ showLinkCopied, setShowLinkCopied ] = useState(false);
  const [ showEmailCopied, setShowEmailCopied ] = useState(false);

  const shareLink = props.report.share_url;

  const handleClose = () => {
    setShowLinkCopied(false);
    setShowEmailCopied(false);
    setShow(false);
  }
  const handleShow = (event) => {
    event.stopPropagation();
    setShow(true);
  }

  const linkCopied = () => {
    setShowLinkCopied(true);
    setShowEmailCopied(false);
  }

  const emailCopied = () => {
    setShowLinkCopied(false);
    setShowEmailCopied(true);
  }

  const emailBody = (props.userSettings.email_template ?? '').replace('(LINK)', shareLink).replace('(link)', shareLink);

  const tooltip = (
    <Tooltip>Share</Tooltip>
  )

    const showEmail = (props.mode ?? 'preliminary') === 'preliminary';

  return (
    <>
      <OverlayTrigger placement='top' overlay={tooltip}>
        <Button onClick={handleShow} style={{"marginLeft": "5px"}} className="btn">
          <FontAwesomeIcon icon={solid("share-nodes")} style={{height: "44px", aspectRatio: "1", color: "#FFFFFF"}} />
        </Button>
      </OverlayTrigger>
      <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Share report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The following link gives access to the property report to anyone with or without an epIMS account, please share it wisely.</p>
            <p><strong>Ref:</strong> { props.report.report_ref }</p>
            <p><strong>Name:</strong> { props.report.name }</p>
            <p>
              <strong>Link:</strong> <input size="50" type="text" value={shareLink} disabled></input>
              <CopyToClipboard text={shareLink} onCopy={linkCopied} >
                <button className="mx-2 btn btn-primary btn-sm"><FontAwesomeIcon icon={solid("copy")} style={{color: "#FFFFFF"}} />&nbsp;&nbsp;&nbsp;Copy</button>
              </CopyToClipboard>{ showLinkCopied ? <>Copied</> : null }
            </p>
            { showEmail && 
              <>
                <p>
                  <strong>Email:</strong>
                  <CopyToClipboard text={emailBody} onCopy={emailCopied} >
                    <button className="mx-2 btn btn-primary btn-sm">
                      <FontAwesomeIcon icon={solid("copy")} style={{color: "#FFFFFF"}} />&nbsp;&nbsp;&nbsp;Copy
                    </button>
                  </CopyToClipboard>
                  { showEmailCopied ? <>Copied</> : null }
                </p>
                <p><textarea rows="10" cols="80" value={emailBody} disabled /></p>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ShareReportButton;