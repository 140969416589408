import { useState } from 'react';
import Draggable from 'react-draggable';
import PointsCollector from './PointsCollector';

function ReorderableMeasuresList(props) {

  const [dragInProgress, setDragInProgress] = useState(false);
  const [itemDraggedIndex, setItemDraggedIndex] = useState();

  const [moveSteps, setMoveSteps] = useState(0);

  const handleDragStart = (event, i) => {
    setDragInProgress(true);
    setItemDraggedIndex(i);
    setMoveSteps(0);
  }

  const handleDrag = (event, data) => {    
    setMoveSteps(Math.min((props.measuresOrder.length - 1) - itemDraggedIndex, Math.max(-itemDraggedIndex, Math.round(data.lastY / divHeight))));
  }

  const handleDragEnd = () => {
    setDragInProgress(false);
    // itemDraggedIndex = the index of the item being dragged
    // moveSteps = how many places it should be moved
    const newIndex = itemDraggedIndex + moveSteps // the new location of the dragged item
    if (newIndex < 0 || newIndex >= props.measuresOrder.length) {
      return; // the requested move is out of bounds (shouldn't happen anyway)
    }

    const newMeasuresOrder = [...props.measuresOrder];

    // shuffle the items that were jumped over along by +/- 1
    if (moveSteps > 0) {
      for (let i = itemDraggedIndex; i < newIndex; i++) {
        newMeasuresOrder[i] = props.measuresOrder[i + 1]
      }
    } else if (moveSteps < 0) {
      for (let i = itemDraggedIndex; i > newIndex; i--) {
        newMeasuresOrder[i] = props.measuresOrder[i - 1]
      }
    }
    // insert the moved element into its new place
    newMeasuresOrder[newIndex] = props.measuresOrder[itemDraggedIndex]
    // update state
    props.setMeasuresOrder(newMeasuresOrder);
    props.setMeasures(props.applyTotalsToMeasures(newMeasuresOrder, props.measures));
  }

  const handlePointsDeltaUpdate = (event, updatedIndex, collecting) => {
    // updating the object within the measures array directly does not trigger a display update since the array is still the same list of objects, no change on the array is triggered
    // setMeasures (and of the useState setter functions really), can instead of a new value, be given a function that takes as a parameter the current value of the property
    // being set and can return a slightly different one based on that and whatever other logic using whatever other values.
    // Here, just keep it all the same except the particular measureId being changed, which is set to again exactly the same thing apart from the 'points' value which is overridden
    var newMeasures = {};
    
    var newIncrease = parseInt(event.target.value);
    if (isNaN(newIncrease)) {
        newIncrease = 0;
    }

    if (collecting === 'EPC') {
        newMeasures = {
            ...props.measures,
            [props.measuresOrder[updatedIndex]]: {...props.measures[props.measuresOrder[updatedIndex]], epcIncrease: newIncrease }
        }
    } else if (collecting === 'EIR') {
        newMeasures = {
            ...props.measures,
            [props.measuresOrder[updatedIndex]]: {...props.measures[props.measuresOrder[updatedIndex]], eirIncrease: newIncrease }
        }
    }
    newMeasures = props.applyTotalsToMeasures(props.measuresOrder, newMeasures);
    props.setMeasures(newMeasures);
  }

  const handlePointsTotalUpdate = (event, updatedIndex, collecting) => {
    // firstly, ignore anything that isn't a real value
    var newTotal = parseInt(event.target.value); // 'total' refers to total at this point in the list
    if (isNaN(newTotal)) {
        return;
    }

    // find the value before this one, if this is the first value then use the base starting value
    var prevTotal;
    if (updatedIndex === 0) {
        if (collecting === 'EPC') {
            prevTotal = props.epcStart;
        } else {
            prevTotal = props.eirStart;
        }
    } else {
        var prevMeasure = props.measures[props.measuresOrder[updatedIndex - 1]];
        if (collecting === 'EPC') {
            prevTotal = prevMeasure.epcTotal
        } else {
            prevTotal = prevMeasure.eirTotal
        }
    }

    // calculate how many points increase this is
    var newIncrease = newTotal - prevTotal;

    // apply the new pointsIncrease and trigger a recalculation of the total, this should give the same total that was typed
    var newMeasures = {};
    if (collecting === 'EPC') {
        newMeasures = {
            ...props.measures,
            [props.measuresOrder[updatedIndex]]: {...props.measures[props.measuresOrder[updatedIndex]], epcIncrease: newIncrease }
        }
    } else if (collecting === 'EIR') {
        newMeasures = {
            ...props.measures,
            [props.measuresOrder[updatedIndex]]: {...props.measures[props.measuresOrder[updatedIndex]], eirIncrease: newIncrease }
        }
    }
    props.setMeasures(props.applyTotalsToMeasures(props.measuresOrder, newMeasures));
  }



  const divHeight = 68;

  return (
    <div style={{marginBottom: "10px"}}>
      {
        props.measuresOrder.map((measureId, index) => {
            var zIndex = 1;
            var translateY = 0;
            var opacity = 1;
            var rotation = "0deg";
            if (dragInProgress) {
              if (itemDraggedIndex === index) {
                zIndex = 1000;
                rotation = "5deg";
              } else {
                opacity = 0.45;
              }
              if (moveSteps < 0) {
                if (index < itemDraggedIndex && index >= itemDraggedIndex - Math.abs(moveSteps)) {
                  translateY = divHeight;
                }
              } else if (moveSteps > 0) {
                if (index > itemDraggedIndex && index <= itemDraggedIndex + Math.abs(moveSteps)) {
                  translateY = -divHeight;
                }
              }
            }
            return  <Draggable key={"draggable" + index} position={{x: 0, y: translateY}} cancel="input" onStop={handleDragEnd} onStart={(e) => handleDragStart(e, index)} onDrag={(e, data) => handleDrag(e, data)}>
                      <div className="outerDragArea" key={"container" + index} style={{display: "flex", alignItems: 'center', opacity: opacity, position: "relative", zIndex: zIndex, margin: "0px", padding: "0px", height: divHeight + "px"}}> { /* This is a wrapper div around the component that gets displayed. It is essentially this one that is being dragged, it adds a bit of padding so that if the user 'missed' with the start of the drag the item is picked up anyway */ }
                        <div key={"measure" + index} style={{cursor: "grab", rotate: rotation, border: "1px solid #000000", borderRadius: "9px", backgroundColor: "#E0E0E0", margin: "5px", width: "100%", padding: "5px"}}>
                            <div style={{margin: "0px", padding: "0px"}} >
                                        <span style={{width: "250px", fontWeight: "bold"}}>{props.measures[measureId].name}</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <span style={{width: "75px"}}>&#43;&pound;{ props.measures[measureId].implementationCost.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }</span>
                                        <span style={{width: "75px"}}>&#61;&pound;{ props.measures[measureId].costTotal.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }</span>
                                        <span style={{width: "159px"}}>
                                            {props.allowPointsChange &&<PointsCollector
                                                    collect={props.plan.collect}
                                                    measure={props.measures[measureId]}
                                                    handlePointsDeltaUpdate={(e) => handlePointsDeltaUpdate(e, index, props.plan.collect)}
                                                    handlePointsTotalUpdate={(e) => handlePointsTotalUpdate(e, index, props.plan.collect)}
                                                />
                                            }
                                        </span>
                            </div>
                        </div>
                      </div>
                    </Draggable>
          })
      }
    </div>
  )

}

export default ReorderableMeasuresList;