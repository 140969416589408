import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function DeleteReportButton(props) {
  const [show, setShow] = useState(false);

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.stopPropagation();
    setShow(true);
  }

  const onDelete = (event) => {
    setDeleteInProgress(true);
    props.deleteReport(props.report.report_id, "delete");
    setShow(false);
  }

  const tooltip = (
    <Tooltip>Delete</Tooltip>
  )

  return (
    <>
      <OverlayTrigger placement="top" overlay={tooltip}>
        <Button onClick={handleShow} style={{"marginLeft": "5px"}} className="btn btn-danger" disabled={deleteInProgress}>
          { 
            !deleteInProgress ? 
            <FontAwesomeIcon icon={solid("trash")} style={{height: "44px", aspectRatio: "1", color: "#FFFFFF"}} /> :
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{height: "44px", aspectRatio: "1", color: "#FFFFFF"}} />
          }
        </Button>
      </OverlayTrigger>
      
      <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete report?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Permanently delete this report?</p>
            <p><strong>Ref:</strong> { props.report.report_ref }</p>
            <p><strong>Name:</strong> { props.report.name }</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onDelete}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default DeleteReportButton;