import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import CompletedUpload from "./components/CompletedUpload";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function Settings(props) {

  const navigate = useNavigate();
  const [overprintName, setOverprintName] = useState('');
  const [overprintColour, setOverprintColour] = useState('');
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [overprintActive, setOverprintActive] = useState(false);
  const [useWhichImage, setUseWhichImage] = useState('no');
  const [existingImageName, setExistingImageName] = useState('');
  const [existingImageUrl, setExistingImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const [emailTemplate, setEmailTemplate] = useState('');
  const emailTemplateLengthLimit = 1000;

  const [newImageInError, setNewImageInError] = useState(false);

  const handleClose = () => setShowModal(false);

  // initial message
  const logoDropzoneInitialMessage = (<>Drop your new logo here (or click to select it)<br/>Please use a .png, .jpg, .gif, .svg or .bmp file 1MB or smaller</>);
  
  // initial file upload state
  const [logoFiles, setLogoFiles] = useState([]);
  
  // onDrop handlers
  const logoOnDrop = useCallback(acceptedFiles => {
    doUpload(acceptedFiles, 'LOGO')
    setLogoFiles([...logoFiles, ...acceptedFiles])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoFiles]);

  // remove file handlers
  const removeLogoFiles = () => {
    setLogoFiles([]);
  }

  // dropzone disabled or not (becomes disabled once upload starts)
  const [logoDropzoneDisabled, setLogoDropzoneDisabled] = useState(false);

  // dropzone props
  const { getRootProps:getLogoRootProps, getInputProps:getLogoInputProps } = useDropzone({
    onDrop: logoOnDrop,
    disabled: logoDropzoneDisabled || useWhichImage !== 'new'
  })

  // display content of the drop zone
  const [logoDropzoneDetail, setLogoDropzoneDetail] = useState(logoDropzoneInitialMessage);

  // filenames as put to server
  const [logoFilename, setLogoFilename] = useState("");

  // NB: In UploadXmlPage where I am copying this code from, we keep the original filename as it was on the client computer as well, in this case we don't need it

  const resetUploads = () => {
    removeLogoFiles();
    setLogoDropzoneDetail(logoDropzoneInitialMessage);
    setLogoFilename('');
    setLogoDropzoneDisabled(false);
  }

  const doUpload = (files, which_one) => {  
    if (which_one !== 'LOGO') {
      return;
    }

    setLogoDropzoneDisabled(true);

    var extension = files[0]['name'].split('.').pop().toLowerCase();

    if (!(['jpg', 'jpeg', 'svg', 'bmp', 'png', 'gif'].includes(extension))) {
      // the file extension wasn't a good one
      setLogoDropzoneDetail(<CompletedUpload success={false} error="Unknown image file type, please try .jpg .png .svg .bmp or .gif" filename={files[0].name} resetUpload={resetUploads} />);
      return;
    }
    
    setLogoDropzoneDetail("Starting upload...");
    setLogoFilename(''); // should be empty already, but make sure - I use if this is set to anything or not to tell when the upload is completed or now when save button is pressed
    
    const axiosConfig = {
      onUploadProgress: function(progressEvent) {
        var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        var uploadMessage = percentComplete + "% complete"
        setLogoDropzoneDetail(uploadMessage);
      }
    }

    const url = process.env.REACT_APP_API_END_POINT + 'upload';
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'extension': extension,
          'isLogo': true
        }),
        method: "POST",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setLogoDropzoneDetail("Sending file...");
          axios.put(data['upload_to'], files[0], axiosConfig)
            .then(res => uploadComplete(res, data['filename'], files[0]['name'], which_one));
        } else {
          setLogoDropzoneDetail(data['error']);
        }
      });
    
  }

  const uploadComplete = (axiosEvent, filename, originalFilename, which_one) => {
    if (which_one != 'LOGO') {
      return;
    }
    setLogoFilename(filename);
    setLogoDropzoneDisabled(true);
    uploadLogoFollowup(filename, originalFilename);
  }

  const uploadLogoFollowup = (filename, originalFilename) => {
    // the endpoint was created when upload processes were only validating XML but now it validates other upload types too
    const url = process.env.REACT_APP_API_END_POINT + 'uploadxmlfollowup';
    fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            filename,
            which_one: 'LOGO',
        }),
        credentials: "include"
    })
    .then((resp) => resp.json())
    .then((data) => {
        if (data['success'] === true) {
          setLogoDropzoneDetail(<CompletedUpload success={true} filename={originalFilename} resetUpload={resetUploads} />);
          setUseWhichImage('new');
          setNewImageInError(false);
        } else {
          setLogoDropzoneDetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={resetUploads}/>);
          setNewImageInError(true);
        } 
    });
    
  }
  
  const applySettings = (event) => {
    event.preventDefault();
  }

  const goHome = () => {
    navigate('/home');
  }

  const doSaveSettings = (event) => {
    event.preventDefault();

    if (overprintActive === true) {
      if (overprintName.trim() === '') {
        setErrorMessage("You must specify a company name or disable custom name and logo")
        setErrorMessage2("Please add a company name and then resave");
        setShowModal(true);
        return;
      }
      
      if (useWhichImage === 'new' && logoFilename === '') {
        setErrorMessage("You asked to use a new logo but did not provide a new logo file or it is still uploading.")
        setErrorMessage2("Please provide the logo file or wait for it to finish uploading.")
        setShowModal(true);
        return;
      }
    }

    if (emailTemplate.length > emailTemplateLengthLimit) {
      setErrorMessage("Email template must be " + emailTemplateLengthLimit + " characters long or shorter.")
      setErrorMessage2("Please provide a shorter version.");
      setShowModal(true);
      return;
    }

    const url = process.env.REACT_APP_API_END_POINT + 'usersettings/';
    var image = '';
    if (useWhichImage === 'new') {
      if (newImageInError) {
        image = existingImageName
      } else {
        image = logoFilename;
      }
    } else if (useWhichImage === 'existing') {
      image = existingImageName;
    } else { // 'no'
      image = ''; // which it was anyway
    }
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          "image": image,
          "overprint": overprintActive,
          "company_name": overprintName,
          "email_template": emailTemplate,
          "overprint_colour": overprintColour,
        })
      })
      .then((resp) => resp.json())
      .then((data) => {
        goHome();
      }); 
  }

  const loadSettings = () => {

    const url = process.env.REACT_APP_API_END_POINT + 'usersettings/';

    fetch(url, 
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setSettingsLoaded(true);
          // depending on when the user was created the settings may not exist or even be set
          var settings = data['settings'];
          if ("overprint" in settings) {
            setOverprintActive(settings['overprint']);
          }
          if ("company_name" in settings) {
            setOverprintName(settings['company_name']);
          }
          setOverprintColour(settings['overprint_colour']);
          if ("image" in settings) {
            setExistingImageName(settings['image']);
            if (settings['image'] === '') {
              setUseWhichImage('no');
            } else {
              setUseWhichImage('existing');
            }
          }
          if ("logo_url" in settings) {
            setExistingImageUrl(settings['logo_url']);
          }
          if ("email_template" in settings) {
            setEmailTemplate(settings['email_template']);
          }
        } else {
          navigate('/');
        }
      }
    );
  }

  const handleOverprintNameChange = (event) => {
    setOverprintName(event.target.value);
  }

  const handleOverprintColourChange = (event) => {
    setOverprintColour(event.target.value);
  }

  const handleOverprintActiveChange = (event) => {
    if (event.target.checked === true) {
      setOverprintActive(true);
    } else {
      setOverprintActive(false);
    }
  }

  const handleLogoChoiceChange = (event) => {
    setUseWhichImage(event.target.value);
  }

  useEffect(() => {
    if (!settingsLoaded) { // this is odd, why not did I not do it with useEffect(..., [])
      loadSettings();
    }
  });

  return (
    <>
      <div className="contentsAreaHeader">
        <div className="areaTitle">Branding</div>
        <div className="areaExplainer">Configure your branding details to add to report pages</div>
      </div>
      <div className="contents">
        <div style={{width: "1000px"}}>
          <div>
            { !settingsLoaded ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> : 
            <>
              <form onSubmit={applySettings}>

                <div className="form-check form-switch">

                  <input className="form-check-input open-content" type="checkbox" role="switch" id="overprintEnable" checked={overprintActive} onChange={handleOverprintActiveChange} />
                  <label className="form-check-label" htmlFor="overprintEnable"><strong>Use custom name and logo in report</strong></label>
                  
                  <div className="content-open fade-new">
                  
                    <div className="row mt-2">
                      <label htmlFor="overprintName" className="col-sm-2 col-form-label"><strong>Company name:</strong></label>
                      <div className="col-sm-4">
                        <input id="overprintName" className="form-control" type="text" disabled={!overprintActive} placeholder="Example Ltd" value={overprintName} onChange={handleOverprintNameChange} />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <label className="col-sm-2 col-form-label"><strong>Custom logo:</strong></label>
                      <div className="col-sm-4">

                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="logoChoice" id="logoChoice3" value="no" disabled={!overprintActive} checked={useWhichImage==='no'} onChange={handleLogoChoiceChange} />
                          <label className="form-check-label" htmlFor="logoChoice3">
                            No logo
                          </label>
                        </div>

                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="logoChoice" id="logoChoice1" value="existing" disabled={!overprintActive || existingImageName === ''} checked={useWhichImage==='existing'} onChange={handleLogoChoiceChange} />
                          <label className="form-check-label" htmlFor="logoChoice1">
                            Existing logo
                          </label>
                          { existingImageUrl === '' ? <></>: <div><img alt="Existing logo" style={{maxWidth: "150px", maxHeight: "150px"}} src={existingImageUrl} /></div> }
                        </div>

                        <div className="form-check">
                          <input className="form-check-input open-content" type="radio" name="logoChoice" id="logoChoice2" value="new" disabled={!overprintActive} checked={useWhichImage==='new'} onChange={handleLogoChoiceChange} />
                          <label className="form-check-label" htmlFor="logoChoice2">
                            New logo
                          </label>
                          <div className="col-sm-4 content-open fade-new">
                            <div {...getLogoRootProps({className: "dropzone"})}>
                              <input {...getLogoInputProps()} />
                              { logoDropzoneDetail }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <label htmlFor="overprintColour" className="col-sm-2 col-form-label"><strong>Brand colour:</strong></label>
                      <div className="col-sm-4">
                        <input id="overprintColour" className="form-control" type="color" style={{width: "200px", height: "50px"}} disabled={!overprintActive} value={overprintColour} onChange={handleOverprintColourChange} />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="emailTemplateTextArea" className="mb-1 form-label"><strong>Email template:</strong></label>
                  <div className="mb-3" style={{width: "500px"}}>Provide an email template for sending epIMS links to your clients, this will be displayed back to you on the share link screen with the text &quot;(LINK)&quot; replaced by the actual link.</div>
                  <textarea className="form-control" width="100%" rows="12" onChange={e => setEmailTemplate(e.target.value)} value={emailTemplate} />
                  <div>Characters used: {emailTemplate.length}/{emailTemplateLengthLimit}</div>
                </div>

                <div className="form-group">
                  <button type="submit" onClick={doSaveSettings} className="btn btn-primary btn-sm">Save</button>
                  &nbsp;
                  <button type="button" onClick={goHome} className="btn btn-secondary btn-sm">Cancel</button>
                </div>
              </form>
            </> }
          </div>



          <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
            <Modal
              show={showModal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Please check settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{errorMessage}</p>
                <p>{errorMessage2}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );

}

export default Settings;
