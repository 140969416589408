import { useParams } from "react-router-dom";
import { useState } from "react";
import "./SharedReportInfo.css";
import { AddressElement, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

function PaymentForm(props) {

  const routeParams = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [companyError, setCompanyError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [priority, setPriority] = useState("improveEnergyEfficiency");
  const [priorityError, setPriorityError] = useState("");

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setCompanyError("");
    setEmailError("");
    setPriorityError("");

    setIsProcessing(true);
    setMessage("");
    
    const confirmParams = {
      return_url: `${window.location.origin}/paymentComplete`,
      payment_method_data: {
        billing_details: {
          email: email,
        }
      }
    }

    const url = process.env.REACT_APP_API_END_POINT + 'sharedreportprepayment';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        "report_path": routeParams.id + '/' + routeParams.slug,
        'stage': 'preStripe',
        'pi_id': props.piId,
        'company': companyName,
        'email': email,
        'priority': priority,
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        setIsProcessing(false);
        if ('errors' in data) {
          data.errors.forEach(err => {
            if (err.key === 'company') {
              setCompanyError(err.error);
            }
            if (err.key === 'email') {
              setEmailError(err.error);
            }
            if (err.key === 'priority') {
              setPriorityError(err.error);
            }
          })
          setMessage("Please fix problems above.");
        } else {
          setMessage(data['error']);
        }
      } else {
        doStripePayment(confirmParams);
      }
    });
  }

  const doStripePayment = async (confirmParams) => {

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams,
    });

    if (error) {
      setMessage(error.message);
    }

    setIsProcessing(false);
  };

  /**
   * 
   * @param {*} currency eg, GBP, EUR
   * @param {*} amount Amount in smallest currency unit, eg £19.99 = 1999
   * @returns Pretty string like "£19.99"
   */
  const getAmountText = (currency, amount) => {
    return (currency === 'GBP' ? '£' : currency) + String(amount).slice(0, -2) + '.' + String(amount).slice(-2)
  }

  const amountText = getAmountText(props.costCurrency, props.costAmount)
  const preDiscountAmountText = getAmountText(props.costCurrency, props.costAmount + 10000)

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-8" style={{marginLeft:"20px"}}>

        <div className="row">
          <div className="col pt-2"><strong>Email:</strong></div>
          <div className="text-danger"><strong>{emailError}</strong></div>
          <div><input className="form-control" type="email" name="email" placeholder="example@example.com" onChange={e => setEmail(e.target.value)} value={email} /></div>
        </div>

        <div className="row pt-2">
          <div><strong>Please choose the sentence that best describes you:</strong></div>
          <div className="text-danger"><strong>{priorityError}</strong></div>
          <div>&nbsp;&nbsp;<input 
            id="improveEnergyEfficiencyRadio"
            type="radio"
            name="priority"
            value="improveEnergyEfficiency"
            checked={priority === 'improveEnergyEfficiency'}
            onChange={e => setPriority('improveEnergyEfficiency')}
          /><label style={{display:"inline"}} htmlFor="improveEnergyEfficiencyRadio">&nbsp;I want to improve the energy efficiency of my property</label></div>
          <div>&nbsp;&nbsp;<input
            id="getToCRadio"
            type="radio"
            name="priority"
            value="getToC"
            checked={priority === 'getToC'}
            onChange={e => setPriority('getToC')}
          /><label style={{display:"inline"}} htmlFor="getToCRadio">&nbsp;I want to get my property to an EPC rating of C for the lowest cost possible</label></div>
          <div>&nbsp;&nbsp;<input
            id="improveEIRRadio"
            type="radio"
            name="priority"
            value="improveEIR"
            checked={priority === 'improveEIR'}
            onChange={e => setPriority('improveEIR')}
          /><label style={{display:"inline"}} htmlFor="improveEIRRadio">&nbsp;I want to improve the EIR score of my property</label></div>
          <div>&nbsp;&nbsp;<input
            id="improveComfortRadio"
            type="radio"
            name="priority"
            value="improveComfort"
            checked={priority === 'improveComfort'}
            onChange={e => setPriority('improveComfort')}
          /><label style={{display:"inline"}} htmlFor="improveComfortRadio">&nbsp;I want to improve the comfort of my property</label></div>
        </div>

        <div className="row py-2">
          <div className="col"><strong>Company Name: (optional)</strong> </div>
          <div className="text-danger"><strong>{companyError}</strong></div>
          <div><input className="form-control" type="text" name="company_name" placeholder="Acme Corp" onChange={e => setCompanyName(e.target.value)} value={companyName}/></div>
        </div>

        <AddressElement options={{
          mode: "shipping",
          allowedCountries: ['GB'],
          fields: {
            phone: 'always',
          },
          validation: {
            phone: {
              required: 'never'
            }
          },
          display: {
            name: "full"
          }
        }} />

        <div className="row">
          <div className="pt-3 m-0">
            <strong>Cost: {amountText}</strong> (reduced from {preDiscountAmountText} with SAVE-100 voucher code)
          </div>
          <div className="pt-3 m-0">
            <strong>Please enter your payment details</strong>
          </div>
        </div>

        {message && <div className="text-danger py-2 fs-5" id="payment-message"><strong>{message}</strong></div>}

        <PaymentElement options={{}} />
        <div className="row">
          <div className="pt-2 pb-2" style={{fontSize:"0.65rem"}}>
            By purchasing an epIMS premium report you agree to the <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Terms.pdf"} target="_blank" rel="noreferrer">epIMS Premium Report Terms &amp; Conditions.</a>
          </div>
        </div>
     
        <button className="btn btn-xx-large btn-primary" disabled={isProcessing} id="submit">
          {isProcessing ? "Processing..." : "Pay now"}
        </button>
      </div>
    </form>
  );
}

export default PaymentForm;
