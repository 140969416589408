import React, { useState, useEffect } from 'react';
import ReportsListItem from './ReportsListItem';
import { subscribe, unsubscribe } from '../events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Paginator from './Paginator';

function ReportsList(props) {

    const [reports, setReports] = useState([]);
    const [reportsFiltered, setReportsFiltered] = useState([]);

    const [reportsLoaded, setReportsLoaded] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 20;

    const deleteReport = (reportId, deleteMode) => {
      const url = process.env.REACT_APP_API_END_POINT + 'reportdelete';
      fetch(url, {
        headers: {
          'Accept': 'application/json',
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          "report_id": reportId,
          "delete_mode": deleteMode
        })
      })
      .then((resp) => resp.json())
      .then((data) => {
        // should check the repsonse?
      });
    }

    const fetchReportList = (forced) => {
      
      var fUrl = process.env.REACT_APP_API_END_POINT + 'reports/?mode=' + props.mode + '&';
      if (props.deleted) {
        fUrl = fUrl + 'deleted=yes&';
      }
      if (forced) {
        fUrl = fUrl + 'forced=yes&';
      }

      fetch(fUrl, {
        headers: {
          'Accept': 'application/json',
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setReports(data['reports_list']);
          setReportsLoaded(true);
        } else {
          setReports([]);
          setReportsLoaded(false);
        }
      });
    }

    const getReportListItem = (report) => {
      return (
        <ReportsListItem userRole={props.userRole} mode={props.mode} deleted={props.deleted} deleteReport={deleteReport} report={report} key={report['report_id']} userSettings={props.userSettings} />
      );
    }

    // on component mount, fetch the latest report list (it'll get shown) and then subscribe to messages indicating that its been updated
    // on one of those messages, fetch again
    // on unmount, unsubscribe
    useEffect(() => {
      fetchReportList();
      const handleReportListUpdateMessage = (body) => {
        fetchReportList();
      };
      subscribe('reportListUpdate', handleReportListUpdateMessage);
      return () => {
        unsubscribe('reportListUpdate', handleReportListUpdateMessage);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goPreviousPage = () => {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1)
      }
    }

    const goNextPage = () => {
      const maxPage = Math.ceil(reportsFiltered.length / pageSize);
      if (pageNumber < maxPage) {
        setPageNumber(pageNumber + 1)
      }
    }

    useEffect(() => {
      const maxPage = Math.ceil(reportsFiltered.length / pageSize);
      if (maxPage === 0) {
        setPageNumber(1)
      } else if (pageNumber > maxPage) {
        setPageNumber(maxPage)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportsFiltered]);

    useEffect(() => {
      setReportsFiltered(reports.filter(report => {
        const reportSearchableText = report.name.toLowerCase() + 'X' + report.status.toLowerCase() + 'X' + report.report_ref.toLowerCase() + 'X' + report.assessor_ref.toLowerCase();
        return reportSearchableText.includes(searchQuery.toLowerCase())
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports, searchQuery])

    const getReportList = () => {
      if (reportsFiltered.length === 0) {
        return (
          <div className="fs-4" style={{ textAlign: "center" }}>No reports matched your search</div>
        );
      }
      return reportsFiltered.slice((pageNumber - 1) * pageSize, pageNumber * pageSize).map((report) => getReportListItem(report))
    }

    const getMainContent = () => {
      if (!reportsLoaded) {
        return <div><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} /></div>
      }
      if (reports.length === 0) {
        if (props.deleted) {
          return (
            <div className="fs-4" style={{ textAlign: "center" }}>You do not have any deleted reports</div>
          );
        } else {
          return (
            <div className="fs-3" style={{ margin: "auto", textAlign: "center" }}>To create an epIMS report, click &apos;Create Report&apos; in the menu on the left</div>
          );
        }
      }

      return (
        <>
          <div className="utilsArea">
            <div className="searchBox">
              <div className="searchIcon"><FontAwesomeIcon icon={solid("magnifying-glass")} style={{color: "#C0C0C0", height: "24px", width: "24px" }} /></div>
              <input className="form-control searchInput" type="text" placeholder='Search...' onChange={e => setSearchQuery(e.target.value)} value={searchQuery} />
            </div>
            <Paginator {...{
              itemCount: reportsFiltered.length,
              setPageNumber,
              currentPage: pageNumber,
              pageSize,
              goPreviousPage,
              goNextPage
            }} />
          </div>
          {getReportList()}
          <div className="utilsArea">
            <Paginator {...{
              itemCount: reportsFiltered.length,
              setPageNumber,
              currentPage: pageNumber,
              pageSize,
              goPreviousPage,
              goNextPage
            }} />
          </div>
        </>
      )
    }

    return (
      <>
        <div className="contentsAreaHeader">
          <div className="areaTitle">{props.deleted ? "Deleted Reports" : "Report List"}</div>
          <div className="areaExplainer">{props.deleted ? "Deleted reports will be erased after 30 days" : "Reports generated for your customers"}</div>
        </div>
        <div className="contents">
          {getMainContent()}
        </div>
      </>
    );
}

export default ReportsList;