function FriendlyGreeting(props) {

    const greeting = (() => {
        const hour = new Date().getHours();
        if (hour >= 3 && hour < 12) {
          return `Good morning, ${props.userRealName}`;
        } else if (hour >= 12 && hour < 17) {
            return `Good afternoon, ${props.userRealName}`;
        } else {
            return `Good evening, ${props.userRealName}`;
        }
    })();

    return <div style={{color: "white"}}><p style={{margin: "15px 0 0 0"}}><strong>{greeting}</strong></p></div>
}

export default FriendlyGreeting;