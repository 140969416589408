import "./SharedReportInfo.css";
import Checkout from "./components/Checkout";
import EpimsLogoNew from "./components/EpimsLogoNew";
import SharedReportUpsell from "./SharedReportUpsell";
import FreeReportLandingPage from "./FreeReportLandingPage";

function SharedReportInfo(props) {

  const getHeaderLogosHtml = () => {

    var HeaderDiv;
    if (props.reportData.logo_url) {
      HeaderDiv =
        <div className="row align-items-center">
        <div className="col-12 col-md text-center text-md-start my-0 my-md-0"><EpimsLogoNew small={true} extraClasses="header-logos img-fluid" /></div>
        <div className="col-12 col-md d-flex flex-column-reverse align-items-center text-black my-0 my-md-0"><h6 className="mx-auto responsive-h6">In Association With</h6></div>
        <div className="col-12 col-md text-center text-md-end my-md-0"><img className="header-logos img-fluid float-md-end float-center" alt={props.reportData.company_name} src={props.reportData.logo_url} /></div>
        </div>
    }
    else if (!props.reportData.logo_url && props.reportData.company_name) {
      HeaderDiv = <div className="row align-items-center">
        <div className="col-12 col-md text-center text-md-start my-0 my-md-0"><EpimsLogoNew small={true} extraClasses="header-logos" /></div>
        <div className="col-12 col-md d-flex flex-column-reverse align-items-center text-black my-0 my-md-0"><h6 className="mx-auto responsive-h6">In Association With</h6></div>
        <div className="col"><h5 className="mx-auto">{props.reportData.company_name}</h5></div>
      </div>
    }
    else {
      HeaderDiv = <div className="row align-items-center">
        <div className="col">
          <EpimsLogoNew small={true} extraClasses="header-logos" />
        </div>
      </div>
    }

    return HeaderDiv;

  }

  const getPageTitle = () => {
    if (props.mode === 'upsell') {
      return 'Your Free Report Is Ready!';
    } else {
      return 'Purchase your Premium Report';
    }
  }

  const getReportCover = () => {
    if (props.mode === 'upsell') {
      return '/epimsFreeReportCover.jpg';
    } else {
      return '/epims premium cover.jpg';
    }
  }

  const getMainSection = () => {
    if (props.mode === 'upsell') {
      if (props['reportData']['doRedirect'] ?? false) {
        return (
          <>
            <div style={{display:"flex", justifyContent:"center"}}>
              <button className="btn btn-x-large btn-primary" style={{marginBottom: "10px", textWrap: "balance"}} onClick={() => { window.open(props.reportData.pdfUrl) }}>Press here if your report does not download automatically</button>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className="col-12 col-md-4">
              <div style={{display:"flex", justifyContent:"center"}}>
                <img src={getReportCover()} style={{width:"100%", maxWidth:"300px", height:"auto"}} alt="Front cover of epIMS Premium report" /> 
              </div>
            </div>
            <SharedReportUpsell reportData={props.reportData} /> 
            <div style={{height: "100px"}}></div>
          </>
        )
      }
    } else {
      return (
        <>
          <div className="col-12 col-md-4">
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={getReportCover()} style={{width:"100%", maxWidth:"300px", height:"auto"}} alt="Front cover of epIMS Premium report" /> 
            </div>
          </div>
          <Checkout reportData={props.reportData} />
        </>
      )
    }
  }

  // A few different things we might want to return here.
  // 1) Just a redirect, the redirect may already be issued so just show a button for incase it didn't work
  // 2) Upsell page
  // 3) Premium purchase page
  // This page originally handled all three with a generic template, and then we wanted a redesign of the free report page
  // so check if thats what we're meant to be doing and use a new component for it if so, otherwise fall back to what was already
  // here, even though that will contain logic (later removable), for the original design
  if (props.mode === 'upsell' && !(props['reportData']['doRedirect'] ?? false)) {
    return <FreeReportLandingPage {...{reportData: props.reportData}} />
  } else {
    return (
      <div className="container pt-1">
        {getHeaderLogosHtml()}
        <div id="heading-text" className="row py-0 py-md-5">
          <div className="col">
            <h1 className="text-success text-center">{getPageTitle()}</h1>
            <hr className="rounded" />
          </div>
        </div>
        
        <div className="row">
          { getMainSection() }
        </div>
      </div>
    );
  }
}

export default SharedReportInfo;