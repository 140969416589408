import { useNavigate } from "react-router-dom";
import "./SharedReportInfo.css";

function SharedReportUpsell(props) {

  const navigate = useNavigate();

  const getFreeReport = () => {
   window.open(props.reportData.pdfUrl);
  }

  const payNow = () => {
    navigate('./paynow');
  }

  return (
    <div className="col-12 col-md-8">
      <div className="row text-center py-4">
        <div className="col"><h2 className="text-success">Inside Your Free Report</h2></div>
      </div>
      <div className="row text-left">
        <div className="col">
          <ul className="mb-4" style={{marginLeft:"20px"}}>
            <li><h5><strong>Learn</strong> about your legal obligations with upcoming legislative changes</h5></li>
            <br />
            <li><h5><strong>Discover</strong> the most cost effective way to increase the EPC rating of your property</h5></li>
            <br />
            <li><h5><strong>see</strong> the impact of different improvement measures</h5></li>
            <br />
            <li><h5><strong className="text-primary">*Your free report</strong> will show you the least disruptive improvement measures to increase the EPC rating of your property!</h5></li>
            <br />
          </ul>
          <div className="row g-2">
            <div className="col bg-light py-3">
              <ul style={{marginLeft:"20px"}}>
                <li><h5>Access your free report now, through the following link!</h5></li>
              </ul>
            </div>
          </div>
          <div className="row text-center">
            <div className="col bg-light pb-2">
              <button className="btn btn-xx-large btn-primary" onClick={getFreeReport}>Download Your Free Report NOW!</button>
            </div>
          </div>
          <div className="row text-center">
            <div className="col bg-light pb-2">
              <button className="btn btn-xx-large btn-primary" onClick={payNow}>Purchase your premium report!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharedReportUpsell;