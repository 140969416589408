import React from 'react';
import Button from 'react-bootstrap/Button';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function PreviewReportButton() {

  const tooltip = (
    <Tooltip>Preview PDF</Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Button style={{"marginLeft": "5px"}} className="btn">
        <FontAwesomeIcon icon={solid("file-Pdf")} style={{height: "44px", aspectRatio: "1", color: "#FFFFFF"}} />
      </Button>
    </OverlayTrigger>
  );
}

export default PreviewReportButton;