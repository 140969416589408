import React from "react";
import { useNavigate } from 'react-router-dom';

function NavOption(props)
{
    const navigate = useNavigate();
    const classes = "navOption" + (props.name === props.activeTab ? ' active' : '');

    const getNotifications = () => {
        if ("notifications" in props) {
            return <span style={{backgroundColor: "red", color: "white", paddingInline: "5px", paddingTop: "0px", paddingBottom: "2px", borderRadius: "8px", fontSize: "16px", fontWeight: "bold", margin: "0 0 0 10px" }}>{props.notifications}</span>
        }
        return null;
    }
    
    return <div className={classes} onClick={() => navigate(props.url)}>{props.displayName}{getNotifications()}</div>
}

export default NavOption;