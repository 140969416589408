import React from 'react';

function EpimsLogoNew(props) {

    const small = props.small ?? false;
    const xsmall = props.xsmall ?? false;

    const extraClasses = props.extraClasses ?? " ";

    var width;

    if (xsmall) {
        width = "13rem";
    }
    else if (small) {
        width = "18rem";
    } 
    else {
        width = "23rem";
    }

    return (
       <img style={{ width: width }} src="/epims dark colour.png" className={ extraClasses } alt="epIMS logo"/>
    );

}

export default EpimsLogoNew;