import React from 'react';

function CompletedUpload(props) {
// , position: "absolute", top: 0, left: 0

    const success = props.success ?? true;

    return (
        <div style={{width: "100%", height: "100%", position: "relative", textAlign: "center", display: "flex", alignItems: "end", justifyContent: "center", paddingBottom: "10px"}}>
            <button type="submit" className="btn btn-primary" style={{zIndex:1000}} onClick={() => props.resetUpload()}>Reset</button>
            <div style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    color: success ? "#30FF30" : "#FF4444",
                    fontSize: "140px",
                    zIndex:1
                }}>
                <strong>{success ? String.fromCharCode(10003) : String.fromCharCode(10005)}</strong>
            </div>
            <div style={{width: "100%", height: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: 0, left: 0, zIndex:1}}>
                {
                    success ?
                    <strong>Uploaded:-<br/>{props.filename}</strong> :
                    <strong>Failed:-<br/>{props.filename}<br/>{props.error}</strong>
                }
            </div>
        </div>
    );

}

export default CompletedUpload;