import { useState, useEffect, useRef } from 'react';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ShareLinkLandingPage from './ShareLinkLandingPage';
import RegisterCheckPage from './RegisterCheckPage';
import RegisterAutoCheckPage from './RegisterAutoCheckPage';
import HomePage from './HomePage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ForgotPasswordDonePage from './ForgotPasswordDonePage';
import LandlordHomePage from './LandlordHomePage';
import OtherUserTypeHomePage from './OtherUserTypeHomePage';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { publish, subscribe, unsubscribe } from './events';
import ResetPasswordPage from './ResetPasswordPage';
import ResetPasswordDonePage from './ResetPasswordDonePage';
import PaymentCompletePage from './PaymentCompletePage';
import PremiumLinkLandingPage from './PremiumLinkLandingPage';
import Footer from './components/Footer';

function Page() {

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [userAccreditationScheme, setUserAccreditationScheme] = useState('');
    const [ sessionChecked, setSessionChecked ] = useState(false)

    const [userSettings, setUserSettings] = useState({});

    const [username, setUsername] = useState('');
    const [userRealName, setUserRealName] = useState('');

    const [pendingReportsCount, setPendingReportsCount] = useState(0);
    const [showPremiumReportsTab, setShowPremiumReportsTab] = useState(false);

    const [ readyForSocket, setReadyForSocket ] = useState(false);
    const { sendMessage, readyState } = useWebSocket(process.env.REACT_APP_API_END_POINT.replace('https://api.', 'wss://wss.'), {
      onOpen: () => {
        // console.log('Websocket connection opened')
      },
      onClose: () => {
        // console.log('Websocket connection closed'),
      },
      shouldReconnect: (closeEvent) => true, // infinitely ?
      reconnectAttempts: null, // (combined with shouldReconnect, means infinite)
      reconnectInterval: (attemptNumber) => {
        return 5000;
      },
      onMessage: (message) => processMessages(message),

    }, readyForSocket);

    const processMessages = (incoming) => {
      const message = JSON.parse(incoming.data);
      publish(message.type, message.body);
    }

    const fetchUserSettings = () => {
      const url = process.env.REACT_APP_API_END_POINT + 'usersettings/';
  
      fetch(url, 
        {
          headers: {
            'Accept': 'application/json',
          },
          method: "GET",
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            setUserSettings(data['settings']); 
          }
        }
      );
    }
  
    const doLogout = () => {
      const url = process.env.REACT_APP_API_END_POINT + 'logout';
      fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
        }),
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        setIsLoggedIn(false);
        setUsername('');
        navigate('/');
      });
    }

    const updateDisplayVars = () => {
      const url = process.env.REACT_APP_API_END_POINT + 'displayvars';
      fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setUserRealName(data['name'])
          setPendingReportsCount(data['prc'])
          setShowPremiumReportsTab(data['sprt'])
          setUserAccreditationScheme(data['scheme'])
        }
      });
    }

    // open and close the websocket according to logged in state
    useEffect(() => {
      if (isLoggedIn) {
        setReadyForSocket(true);
        updateDisplayVars();
      } else {
        setReadyForSocket(false);
      }
    }, [isLoggedIn])

    // On mounting of this component, fetch standard data that is needed to display the right things in the app
    // (eg, users name for the welcome message and what extra tabs to display)
    // Also, subscribe to 'ws-outgoing' events which indicate a desire to send a message over the websocket (this function then actually sends it)
    // unsubscribe on unmount
    useEffect(() => {
      const outgoingMessageHandler = (data) => {
        if (readyState === ReadyState.OPEN) {
          // however: https://github.com/robtaussig/react-use-websocket#readme
          // No more waiting for the WebSocket to open before messages can be sent. Pre-connection messages are queued up and sent on connection - 
          sendMessage(JSON.stringify(data.detail));
        }
      }
      subscribe('ws-outgoing', outgoingMessageHandler);
      subscribe('updateDisplayVars', updateDisplayVars);
      return () => {
        unsubscribe('ws-outgoing', outgoingMessageHandler);
        unsubscribe('updateDisplayVars', updateDisplayVars);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ping = () => {

      if (sessionChecked && !isLoggedIn) {

      } else {
        const url = process.env.REACT_APP_API_END_POINT + 'ping';
        fetch(url, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
          }),
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
          setSessionChecked(true);
          if (data['success']) {
            if (!['landlord', 'surveyor', 'estate agent', 'other'].includes(data['role'])) {
              doLogout();
              return;
            }
            setUsername(data['display_username']); // this one is the function call tha goes all the way to the very parent and then back down (its used in the user dropdown menu)
            setUserRole(data['role']);
            setIsLoggedIn(true);
            if (location.pathname === '/') {
              navigate('/home');
            }
          } else {
            setIsLoggedIn(false); // need to check but my hope is that when this changes the page is rerendered which will mean logged in routes are no longer accessible
            setUsername("");
          }
        });
      }
    }

    function useInterval(callback, delay) {
      const savedCallback = useRef();
    
      // Remember the latest callback.
      useEffect(() => {
        savedCallback.current = callback;
      }, [callback]);
    
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay]);
    }
  
    useInterval(() => { // check the current session status every 30s
      ping()
    }, 30000);

    useEffect(() => {
      // check whenever the route changes, unfortunately this combined with the one on initial page load means there are two pings to begin with
      // but this needs to be here as if the session gets killed while the user is on another page they will redirect back to the homepage in most
      // cases but the session settings are not updated, so the ping is needed as otherwise the login will redirect the user back to the report
      // list page and so on
      //
      // This can be dealt with differently, by having the 
      ping()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const appRoutes = [];
    // these routes are always accessible, regardless of login state
    appRoutes.push([
      <Route key="login" path='/' element={ <LoginPage isLoggedIn={isLoggedIn} setUserRealName={setUserRealName} setUserRole={setUserRole} setIsLoggedIn={setIsLoggedIn} /> } /> ,
      <Route key="signup" path='/signup' element={ <RegisterPage /> } /> ,
      <Route key="signupcheck" path='/confirm' element={ <RegisterCheckPage /> } /> ,
      <Route key="signupcheck-auto" path='/confirm/:username/:code' element={ <RegisterAutoCheckPage /> } /> ,
      <Route key="getpremiumreport" path='/s/premium/:id/:slug' element={ <PremiumLinkLandingPage /> } /> ,
      <Route key="sharelink" path='/s/:id/:slug' element={ <ShareLinkLandingPage mode="upsell" /> } /> ,
      <Route key="sharelinkpaynow" path='/s/:id/:slug/paynow' element={ <ShareLinkLandingPage mode="paynow" /> } /> ,
      <Route key="resetpassword" path="/resetpassword/:username/:code" element={ <ResetPasswordPage /> } /> , 
      <Route key="resetpassworddone" path="/resetpassworddone" element={ <ResetPasswordDonePage /> } /> ,
      <Route key="forgotpassword" path="/forgotpassword" element={ <ForgotPasswordPage /> } /> ,
      <Route key="forgotpassworddone" path="/forgotpassworddone" element={ <ForgotPasswordDonePage /> } /> ,
      <Route key="paymentcomplete" path="/paymentComplete" element={ <PaymentCompletePage /> } />
    ]);
    
    if (isLoggedIn) {
      const homepageParams = {
        doLogout,
        userRealName,
        userRole,
        username,
        userSettings,
        fetchUserSettings,
        setUserRealName,
        pendingReportsCount,
        showPremiumReportsTab,
        userAccreditationScheme
      }
      // add the routes that require a valid session
      appRoutes.push([
        <Route key="account" path="/account" element={ <HomePage {...homepageParams} activeTab="myaccount" /> } />
      ])
      // some routes depend on role...
      var validRole = false;
      if (userRole === 'estate agent' || userRole === 'surveyor') {
        validRole = true;
        appRoutes.push([
          <Route key="home" path='/home' element={ <HomePage activeTab="main" {...homepageParams} /> } /> ,
          <Route key="deleted" path='/deleted' element={ <HomePage activeTab="deleted" {...homepageParams} /> } /> ,
          <Route key="uploadXml" path='/uploadXml' element={ <HomePage activeTab="new" {...homepageParams} /> } /> ,
          <Route key="settings" path='/settings' element={ <HomePage activeTab="settings" {...homepageParams} /> } /> ,
          <Route key="earnings" path='/earnings' element={ <HomePage activeTab="earnings" {...homepageParams} /> } /> ,
          <Route key="pending" path='/pending' element={ <HomePage activeTab="pending" {...homepageParams} /> } /> ,
          <Route key="processPending" path="/pending/:pendingReportId" element={ <HomePage activeTab="pending" {...homepageParams} /> } /> ,
          <Route key="premium" path='/premium' element={ <HomePage activeTab="premium" {...homepageParams} /> } /> ,
        ]);
      }
      if (userRole === 'landlord') {
        validRole = true;
        appRoutes.push([
          <Route key="home" path='/home' element={ <LandlordHomePage doLogout={doLogout} userRealName={userRealName} userRole={userRole} username={username} /> } />
        ]);
      }
      if (userRole === 'other' || !validRole) {
        appRoutes.push([
          <Route key="home" path='/home' element={ <OtherUserTypeHomePage doLogout={doLogout} userRealName={userRealName} userRole={userRole} username={username} /> } />
        ])
      }
    }

    // add catchall route, but only after checking the session
    if (sessionChecked) {
      appRoutes.push([
        <Route key="404" path="*" element={ <Navigate to="/" /> } />
      ]);
    } else {
      
    }

    const showFooterOn = [
      '',
      'signup',
      'confirm',
      's',
      'resetpassword',
      'resetpassworddone',
      'forgotpassword',
      'forgotpassworddone',
      'paymentComplete',
    ];

    const showFooter = showFooterOn.includes(location.pathname.split('/')[1]);

    return (
      <>
        <div className="outerContent">
          <Routes>
            { appRoutes }   
          </Routes>
          {showFooter && <div className="standardFooterWrapper">
            <Footer />
          </div>}
        </div>
      </>
    );
  }

  export default Page;